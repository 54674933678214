import * as React from "react"
import { Layout } from "../components/layout"
import { Container } from "react-bootstrap"
import { Seo } from "../components/seo"

export default function NotFoundPage() {
  return (
    <Layout>
      <Container className="pt-7 pb-4">
        <h1 className="display-2 fw-bold text-center">Pagina bestaat niet</h1>
        <p className="text-center">
          Deze pagina bestaat helaas niet.
        </p>
      </Container>
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="Niet gevonden"
  />
)
